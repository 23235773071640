import React, { useState } from "react";
import {
  CRow,
  CForm,
  CCol,
  CContainer,
  CCard,
  CCardBody,
  CInputGroup,
  CInputGroupText,
  CButton,
  CSpinner,
  CFormInput,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { useNavigate } from "react-router-dom";
import { addToast } from "src/components/Notification";
import { forgotPassword } from "src/providers/auth";
import { toast } from "react-toastify";

const ConfirmEmail = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [loading, setLoading] = useState(false);
  const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; // regex validação de email

  const handleSubmit = async () => {
    if (validate()) {
      try {
        setLoading(true);
        const result = await forgotPassword(email);
        if (result.status === 201) {
          addToast("success", "Enviado com sucesso");
          addToast("success", "Verifique seu email");
          navigate("/login");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        addToast(toast.TYPE.ERROR, error.message);
        console.error("Erro: ", error);
      }
    }
  };

  const validate = () => {
    if (!email) {
      return addToast(
        "error",
        "Campo email vazio",
        "Todos campos do formulário precisam ser preenchidos"
      );
    } else if (!EMAIL_REGEX.test(email)) {
      addToast(toast.TYPE.ERROR, "Informe um email válido");
      return false;
    }
    return true;
  };
  
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol lg={5} md={10} sm={12}>
            <CCard className="p-4 mb-0">
              <CCardBody>
                <CForm onSubmit={handleSubmit}>
                  <h1>Recuperar Senha</h1>
                  <p className="text-medium-emphasis">
                    Digite seu email abaixo
                  </p>
                  <CInputGroup className="my-3">
                    <CInputGroupText>@</CInputGroupText>
                    <CFormInput
                      placeholder="Digite seu email"
                      autoComplete="username"
                      onChange={({ target }) => setEmail(target.value)}
                    />
                  </CInputGroup>
                  <CRow>
                    <CCol xs={6}>
                      <CButton
                        type="submit"
                        color="primary"
                        className="px-4 d-flex justify-content-center align-items-center"
                        disabled={loading}
                        style={{ height: 38, width: 83 }}
                      >
                        {loading ? (
                          <CSpinner color="light" size="sm" />
                        ) : (
                          "Enviar"
                        )}
                      </CButton>
                    </CCol>
                  </CRow>
                  <CRow className="mt-5 justify-content-end">
                    <CButton
                      variant="outline"
                      className="me-2 w-fitContent"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </CButton>
                    <CButton
                      variant="outline"
                      className="w-fitContent"
                      onClick={() => navigate("/register")}
                    >
                      Registre-se
                    </CButton>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ConfirmEmail;

import React from "react";
import { CContainer, CRow, CCol, CCard, CButton } from "@coreui/react";
import { useNavigate } from "react-router-dom";

const ConfirmRegister = () => {
	const navigate = useNavigate()
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer fluid className="p-0">
        <CRow className="d-flex justify-content-center align-items-center p-0 pb-5 m-0 w-100">
          <CCol md={10} className="d-flex flex-column h-100">
			<CCard className="p-5 d-flex flex-column align-items-center">
            	<h1 className="h2 pe-0">Cadastro realizado com sucesso!</h1>
				<p className="mb-5">Acesse seu e-mail para validação do cadastro</p>
				<CButton style={{ width: "fit-content" }} onClick={() => navigate("/login")}>Voltar ao Login</CButton>
			</CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ConfirmRegister;

import { toast } from 'react-toastify';

export function addToast(type, title, message, time = 3000) {
  const config = {
    position: toast.POSITION.TOP_RIGHT,
  }

  switch (type) {
    case toast.TYPE.SUCCESS:
      toast.success(title, config)
      break;
    case toast.TYPE.ERROR:
      toast.error(title, config)
      break;
    default:
      toast.info(title, config)
      break;
  }
}

import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CSpinner,
} from "@coreui/react";
import { resetPassword, validateToken } from "src/providers/auth";
import { addToast } from "src/components/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const [validToken, setValidToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });
  useEffect(() => {
    const token = query.get("token");
    if (token) {
      setPayload({
        ...payload,
        token: token,
      });
      handleValidateToken(token);
    }
  }, []);

  const handleValidateToken = async (token) => {
    try {
      const response = await validateToken(token);
      if (response) {
        setValidToken(response.valid);
      }
    } catch (err) {
      addToast("error", err.message);
    }
  };

  const handleSubmit = async (ev) => {
    ev.preventDefault();
    if (validate()) {
      setLoading(true);
      try {
        const result = await resetPassword(payload);
        if (result.status === 201) {
          addToast("Success", "Senha redefinida com sucesso");
          navigate("/login");
          setLoading(false);
        }
      } catch (err) {
        addToast("error", err.message);
        setLoading(false);
      }
    }
  };

  const validate = () => {
    if (payload.password === "") {
      addToast("error", "O campo nova senha é obrigatório");
    } else if (payload.password.length < 8) {
      addToast("error", "A senha precisa ter no mínimo 8 dígitos");
    } else if (payload.confirmPassword === "") {
      addToast("error", "O campo confirmar senha é obrigatório");
    } else if (payload.password !== payload.confirmPassword) {
      addToast(
        "error",
        "O campo confirmar senha deve ser igual ao campo nova senha"
      );
    } else {
      return true;
    }
  };

  const handlePayload = (key, value) => {
    setPayload({
      ...payload,
      [key]: value,
    });
  };
  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          {validToken ? (
            <CCol lg={6} md={10} sm={12}>
              <CCard className="p-4 mb-0">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1>Recuperar Senha</h1>
                    <p className="text-medium-emphasis">
                      Por favor, insira a nova senha
                    </p>
                    <CInputGroup className="my-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Digite sua senha"
                        autoComplete="password"
                        onChange={({ target }) =>
                          handlePayload("password", target.value)
                        }
                      />
                    </CInputGroup>
                    <CInputGroup className="my-3">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Digite sua senha novamente"
                        autoComplete="password"
                        onChange={({ target }) =>
                          handlePayload("confirmPassword", target.value)
                        }
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          type="submit"
                          color="primary"
                          className="px-4 d-flex justify-content-center align-items-center"
                          disabled={loading}
                          style={{ height: 38, width: 83 }}
                        >
                          {loading ? (
                            <CSpinner color="light" size="sm" />
                          ) : (
                            "Enviar"
                          )}
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                  <CRow className="mt-3 justify-content-end">
                    <CButton
                      type="button"
                      variant="outline"
                      className="me-2 w-fitContent"
                      onClick={() => navigate("/login")}
                    >
                      Login
                    </CButton>
                    <CButton
                      type="button"
                      variant="outline"
                      className="w-fitContent"
                      onClick={() => navigate("/register")}
                    >
                      Registre-se
                    </CButton>
                  </CRow>
                </CCardBody>
              </CCard>
            </CCol>
          ) : (
            <CCol lg={5} md={10} sm={12}>
              <CCard className="p-4 mb-0">
                <div className="mb-5 text-center">
                  <span style={{ fontSize: 30 }}>Token Inválido</span>
                </div>
                <CRow className="mt-3 justify-content-center">
                  <CButton
                    variant="outline"
                    className="me-2 w-fitContent"
                    onClick={() => navigate("/login")}
                  >
                    Login
                  </CButton>
                  <CButton
                    variant="outline"
                    className="w-fitContent"
                    onClick={() => navigate("/register")}
                  >
                    Registre-se
                  </CButton>
                </CRow>
              </CCard>
            </CCol>
          )}
        </CRow>
      </CContainer>
    </div>
  );
};

export default ResetPassword;
